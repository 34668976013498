import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import CurveHeroBg from "../components/common/CurveHeroBg"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"

const BasicPageTemplate = ({ data }) => {
  const { title, subTitle, description } = data?.strapiPage?.sections[0]

  return (
    <MainLayout>
      <CurveHeroBg heading={title} subHeading={subTitle} />
      <section>
        <Container style={{ paddingBottom: 96 }}>
          <div
            dangerouslySetInnerHTML={{ __html: description?.data?.description }}
          />
        </Container>
      </section>
    </MainLayout>
  )
}

export const query = graphql`
  {
    strapiPage(slug: { eq: "privacy-policy" }) {
      sections {
        title
        subTitle
        description {
          data {
            description
          }
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`

export default BasicPageTemplate

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      image="https://invozone-backend.s3.amazonaws.com/Privacy_Policy_4e1cfa00c8.webp"
    />
  )
}
